import React from 'react'

const icon = () => (
  <svg
    id="cat_onramp"
    enable-background="new 0 0 512 512"
    height="24"
    viewBox="0 0 512 512"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m256 196c-33.084 0-60 26.916-60 60s26.916 60 60 60 60-26.916 60-60-26.916-60-60-60zm0 80c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.972 20 20-8.972 20-20 20z" />
      <circle cx="256" cy="376" r="20" />
      <path d="m452 36h-392c-33.084 0-60 26.916-60 60v80c0 33.084 26.916 60 60 60h60v220c0 11.046 8.954 20 20 20h232c11.046 0 20-8.954 20-20v-220h60c33.084 0 60-26.916 60-60v-80c0-33.084-26.916-60-60-60zm-100 400h-192v-280h192zm120-260c0 11.028-8.972 20-20 20h-60v-40h20c11.046 0 20-8.954 20-20s-8.954-20-20-20h-312c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v40h-60c-11.028 0-20-8.972-20-20v-80c0-11.028 8.972-20 20-20h392c11.028 0 20 8.972 20 20z" />
    </g>
  </svg>
)

export default icon
