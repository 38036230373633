import React from 'react'

const icon = () => (
  <svg
    id="cat_collectibles"
    enable-background="new 0 0 512.049 512.049"
    height="24"
    viewBox="0 0 512.049 512.049"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m432.177 249.574c-7.811-7.809-20.473-7.807-28.282.002l-56.565 56.565s-.001.001-.001.001-.001.001-.001.001l-56.567 56.567c-38.991 38.99-102.432 38.99-141.421 0-38.99-38.99-38.99-102.431 0-141.421l56.566-56.566c.001-.001.002-.002.003-.002s.002-.002.002-.003l56.564-56.564c7.81-7.81 7.811-20.471.002-28.282l-56.559-56.578c-7.809-7.812-20.477-7.813-28.287-.002l-113.145 113.145c-85.981 85.981-85.981 225.146 0 311.127 85.982 85.981 225.145 85.981 311.127 0l113.145-113.145c7.808-7.808 7.807-20.48-.002-28.287zm-240.407-183.853 28.279 28.289-28.284 28.284-28.284-28.284zm155.559 353.558c-70.181 70.182-184.375 70.182-254.559 0-70.348-70.348-70.348-184.21 0-254.558l42.427-42.427 28.284 28.284-42.427 42.427c-54.586 54.586-54.585 143.404 0 197.99 54.586 54.586 143.404 54.586 197.99 0l42.426-42.426 28.284 28.284zm70.71-70.71-28.284-28.284 28.285-28.285 28.289 28.279z" />
      <path d="m208.869 222.552c-1.593 6.746.421 13.836 5.323 18.737l33.686 33.686-54.153 18.051c-10.479 3.493-16.142 14.819-12.649 25.298 3.493 10.48 14.818 16.142 25.298 12.649l84.853-28.284c6.576-2.192 11.548-7.633 13.14-14.379 1.593-6.746-.421-13.836-5.323-18.737l-33.686-33.686 54.153-18.051c10.479-3.493 16.142-14.819 12.649-25.298s-14.818-16.141-25.298-12.649l-84.853 28.284c-6.576 2.193-11.548 7.633-13.14 14.379z" />
      <path d="m412.049 200c55.14 0 100-44.86 100-100s-44.86-100-100-100-100 44.86-100 100 44.86 100 100 100zm0-160c33.084 0 60 26.916 60 60s-26.916 60-60 60-60-26.916-60-60 26.916-60 60-60z" />
      <circle cx="412.049" cy="100.003" r="20" />
    </g>
  </svg>
)

export default icon
