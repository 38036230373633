import React from 'react'

const SocialNetworksIcon = () => (
  <svg
    id="cat_social_networks"
    enable-background="new 0 0 512 512"
    height="24"
    viewBox="0 0 512 512"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m316 60c0-33.084-26.916-60-60-60s-60 26.916-60 60 26.916 60 60 60 60-26.916 60-60zm-60 20c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.972 20 20-8.972 20-20 20z" />
      <path d="m176 240h160c11.046 0 20-8.954 20-20v-40c0-33.084-26.916-60-60-60h-80c-33.084 0-60 26.916-60 60v40c0 11.046 8.954 20 20 20zm20-60c0-11.028 8.972-20 20-20h80c11.028 0 20 8.972 20 20v20h-120z" />
      <path d="m196 332c0-33.084-26.916-60-60-60s-60 26.916-60 60 26.916 60 60 60 60-26.916 60-60zm-60 20c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.972 20 20-8.972 20-20 20z" />
      <path d="m176 392h-80c-33.084 0-60 26.916-60 60v40c0 11.046 8.954 20 20 20h160c11.046 0 20-8.954 20-20v-40c0-33.084-26.916-60-60-60zm20 80h-120v-20c0-11.028 8.972-20 20-20h80c11.028 0 20 8.972 20 20z" />
      <path d="m436 332c0-33.084-26.916-60-60-60s-60 26.916-60 60 26.916 60 60 60 60-26.916 60-60zm-60 20c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.972 20 20-8.972 20-20 20z" />
      <path d="m416 392h-80c-33.084 0-60 26.916-60 60v40c0 11.046 8.954 20 20 20h160c11.046 0 20-8.954 20-20v-40c0-33.084-26.916-60-60-60zm20 80h-120v-20c0-11.028 8.972-20 20-20h80c11.028 0 20 8.972 20 20z" />
    </g>
  </svg>
)

export default SocialNetworksIcon
