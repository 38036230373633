import React from 'react'

const icon = () => (
  <svg
    id="cat_exchanges"
    enable-background="new 0 0 512 512"
    height="24"
    viewBox="0 0 512 512"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m484.249 361.443c4.925-8.705 7.751-18.748 7.751-29.443 0-33.084-26.916-60-60-60v-20c0-11.046-8.954-20-20-20s-20 8.954-20 20v20h-60c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v120h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h60v20c0 11.046 8.954 20 20 20s20-8.954 20-20v-20h20c33.084 0 60-26.916 60-60 0-21.219-11.082-39.886-27.751-50.557zm-32.249-29.443c0 11.028-8.972 20-20 20h-40v-40h40c11.028 0 20 8.972 20 20zm0 100h-60v-40h60c11.028 0 20 8.972 20 20s-8.972 20-20 20z" />
      <path d="m80 260c0 11.046 8.954 20 20 20s20-8.954 20-20v-20h20c33.084 0 60-26.916 60-60s-26.916-60-60-60h-80c-11.028 0-20-8.972-20-20s8.972-20 20-20h120c11.046 0 20-8.954 20-20s-8.954-20-20-20h-60v-20c0-11.046-8.954-20-20-20s-20 8.954-20 20v20h-20c-33.084 0-60 26.916-60 60s26.916 60 60 60h80c11.028 0 20 8.972 20 20s-8.972 20-20 20h-120c-11.046 0-20 8.954-20 20s8.954 20 20 20h60z" />
      <path d="m396 96v31.716l-5.858-5.858c-7.811-7.811-20.474-7.811-28.284 0s-7.811 20.474 0 28.284l39.997 39.998c7.789 7.791 20.5 7.791 28.289 0l39.997-39.998c7.811-7.811 7.811-20.474 0-28.284s-20.474-7.811-28.284 0l-5.857 5.858v-31.716c0-33.084-26.916-60-60-60h-80c-11.046 0-20 8.954-20 20s8.954 20 20 20h80c11.028 0 20 8.972 20 20z" />
      <path d="m216 436h-80c-11.028 0-20-8.972-20-20v-31.716l5.858 5.858c7.811 7.811 20.474 7.811 28.284 0 7.811-7.811 7.811-20.474 0-28.284l-39.997-39.998c-7.933-7.93-20.636-7.65-28.289 0l-39.997 39.998c-7.811 7.811-7.811 20.474 0 28.284s20.474 7.811 28.284 0l5.857-5.858v31.716c0 33.084 26.916 60 60 60h80c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
    </g>
  </svg>
)

export default icon
