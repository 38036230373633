import React from 'react'

const icon = () => (
  <svg
    id="cat_marketplaces"
    enable-background="new 0 0 512 512"
    height="24"
    viewBox="0 0 512 512"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m296 80c11.046 0 20-8.954 20-20v-40c0-11.046-8.954-20-20-20s-20 8.954-20 20v40c0 11.046 8.954 20 20 20z" />
      <path d="m492 200h-136v-51.716l34.143-34.142c3.75-3.75 5.857-8.838 5.857-14.142v-80c0-11.046-8.954-20-20-20s-20 8.954-20 20v71.716l-34.143 34.142c-3.75 3.75-5.857 8.838-5.857 14.142v60h-40v-60c0-5.304-2.107-10.392-5.857-14.142l-34.143-34.142v-71.716c0-11.046-8.954-20-20-20s-20 8.954-20 20v80c0 5.304 2.107 10.392 5.857 14.142l34.143 34.142v51.716h-116v-60c0-33.084-26.916-60-60-60h-40c-11.046 0-20 8.954-20 20s8.954 20 20 20h40c11.028 0 20 8.972 20 20v152c0 55.14 44.859 100 100 100h232c55.141 0 100-44.86 100-100v-72c0-11.046-8.954-20-20-20zm-20 92c0 33.084-26.916 60-60 60h-232c-33.084 0-60-26.916-60-60v-52h352z" />
      <path d="m120 452c0 33.084 26.916 60 60 60s60-26.916 60-60-26.916-60-60-60-60 26.916-60 60zm60-20c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.972-20-20 8.972-20 20-20z" />
      <path d="m352 452c0 33.084 26.916 60 60 60s60-26.916 60-60-26.916-60-60-60-60 26.916-60 60zm60-20c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.972-20-20 8.972-20 20-20z" />
    </g>
  </svg>
)

export default icon
