import React from 'react'

const icon = () => (
  <svg
    id="cat_defi"
    enable-background="new 0 0 512 512"
    height="24"
    viewBox="0 0 512 512"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m376 116h-220c-33.084 0-60 26.916-60 60v160c0 33.084 26.916 60 60 60h220c11.046 0 20-8.954 20-20v-60h20c11.046 0 20-8.954 20-20v-80c0-11.046-8.954-20-20-20h-20v-60c0-11.046-8.954-20-20-20zm-20 240h-200c-11.028 0-20-8.972-20-20v-160c0-11.028 8.972-20 20-20h200v40h-20c-33.084 0-60 26.916-60 60s26.916 60 60 60h20zm40-80h-60c-11.028 0-20-8.972-20-20s8.972-20 20-20h60z" />
      <path d="m256 0c-49.993 0-98.457 14.315-140 40.885v-20.885c0-11.046-8.954-20-20-20s-20 8.954-20 20v61.112c0 7.888 4.637 15.039 11.838 18.258 7.203 3.219 15.623 1.905 21.5-3.356 40.358-36.121 92.443-56.014 146.662-56.014 121.122 0 216 94.878 216 216 0 38.688-10.176 76.726-29.429 110.002-5.532 9.561-2.266 21.796 7.295 27.327 9.56 5.531 21.796 2.265 27.327-7.295 22.771-39.356 34.807-84.321 34.807-130.034 0-143.552-112.449-256-256-256z" />
      <path d="m424.163 412.629c-7.203-3.219-15.623-1.905-21.5 3.356-40.359 36.122-92.445 56.015-146.663 56.015-121.122 0-216-94.878-216-216 0-37.638 9.662-74.772 27.94-107.387 5.4-9.636 1.967-21.825-7.669-27.225-9.634-5.4-21.825-1.967-27.225 7.669-21.619 38.576-33.046 82.472-33.046 126.943 0 143.551 112.449 256 256 256 49.993 0 98.457-14.315 140-40.885v20.885c0 11.046 8.954 20 20 20 11.045 0 20-8.955 20-20v-61.112c0-7.888-4.636-15.039-11.837-18.259z" />
    </g>
  </svg>
)

export default icon
