import React from 'react'

const icon = () => (
  <svg
    id="cat_utilities"
    enable-background="new 0 0 512 512"
    height="24"
    viewBox="0 0 512 512"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m256 160c-11.046 0-20 8.954-20 20v40c0 11.046 8.954 20 20 20s20-8.954 20-20v-40c0-11.046-8.954-20-20-20z" />
      <path d="m256 0c-99.521 0-180 80.479-180 180 0 51.78 21.744 98.734 57.579 131.844 14.458 13.448 22.421 30.124 22.421 46.955v53.201c0 33.084 26.916 60 60 60h20v20c0 11.046 8.954 20 20 20s20-8.954 20-20v-20h20c33.084 0 60-26.916 60-60v-53.201c0-16.831 7.963-33.506 22.371-46.91 36.639-33.851 57.629-81.115 57.629-131.889 0-99.521-80.479-180-180-180zm40 432h-80c-11.028 0-20-8.972-20-20v-16h120v16c0 11.028-8.972 20-20 20zm55.177-149.444c-21.929 20.398-34.332 46.382-35.133 73.444h-120.088c-.802-27.062-13.206-53.048-35.183-73.49-28.872-26.678-44.773-63.083-44.773-102.51 0-55.736 32.742-103.964 80-126.474v38.189l-34.143 34.142c-3.75 3.75-5.857 8.838-5.857 14.142v80c0 11.046 8.954 20 20 20s20-8.954 20-20v-71.716l34.143-34.142c3.75-3.75 5.857-8.838 5.857-14.142v-58.564c6.534-.939 13.21-1.435 20-1.435s13.466.496 20 1.435v58.565c0 5.304 2.107 10.392 5.857 14.142l34.143 34.142v71.716c0 11.046 8.954 20 20 20s20-8.954 20-20v-80c0-5.304-2.107-10.392-5.857-14.142l-34.143-34.142v-38.19c47.258 22.51 80 70.737 80 126.474 0 39.427-15.901 75.832-44.823 102.556z" />
    </g>
  </svg>
)

export default icon
