import React from 'react'

const icon = () => (
  <svg
    id="cat_games"
    enable-background="new 0 0 512 512"
    height="24"
    viewBox="0 0 512 512"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m60 366c-33.084 0-60 26.916-60 60v20c0 33.084 26.916 60 60 60s60-26.916 60-60v-20c0-33.084-26.916-60-60-60zm20 80c0 11.028-8.972 20-20 20s-20-8.972-20-20v-20c0-11.028 8.972-20 20-20s20 8.972 20 20z" />
      <path d="m256 186c-33.084 0-60 26.916-60 60v20c0 33.084 26.916 60 60 60s60-26.916 60-60v-20c0-33.084-26.916-60-60-60zm20 80c0 11.028-8.972 20-20 20s-20-8.972-20-20v-20c0-11.028 8.972-20 20-20s20 8.972 20 20z" />
      <path d="m452 366c-33.084 0-60 26.916-60 60v20c0 33.084 26.916 60 60 60s60-26.916 60-60v-20c0-33.084-26.916-60-60-60zm20 80c0 11.028-8.972 20-20 20s-20-8.972-20-20v-20c0-11.028 8.972-20 20-20s20 8.972 20 20z" />
      <path d="m296 466h-20v-80c0-11.046-8.954-20-20-20h-40c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v60h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h80c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
      <path d="m100 286h-20v-80c0-11.046-8.954-20-20-20h-40c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v60h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h80c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
      <path d="m492 286h-20v-80c0-11.046-8.954-20-20-20h-40c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v60h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h80c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
      <path d="m60 6c-33.084 0-60 26.916-60 60v20c0 33.084 26.916 60 60 60s60-26.916 60-60v-20c0-33.084-26.916-60-60-60zm20 80c0 11.028-8.972 20-20 20s-20-8.972-20-20v-20c0-11.028 8.972-20 20-20s20 8.972 20 20z" />
      <path d="m452 6c-33.084 0-60 26.916-60 60v20c0 33.084 26.916 60 60 60s60-26.916 60-60v-20c0-33.084-26.916-60-60-60zm20 80c0 11.028-8.972 20-20 20s-20-8.972-20-20v-20c0-11.028 8.972-20 20-20s20 8.972 20 20z" />
      <path d="m296 106h-20v-80c0-11.046-8.954-20-20-20h-40c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v60h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h80c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
    </g>
  </svg>
)

export default icon
